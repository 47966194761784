<template>
    <div id="contact">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <h1 class="title fw-bold">Contact Us</h1>
                </div>
                <div class="col-lg-7">
                    <div class="card">
                        <div class="card-body p-3 p-md-5">
                            <div class="mb-3">
                                <label for="subject" class="form-label">Subject</label>
                                <input type="text" class="form-control form-control-lg" id="subject" v-model="subject">
                            </div>

                            <div class="col-12 mb-3">
                                <label for="content" class="form-label">Content</label>
                                <textarea id="content" class="form-control" rows="5" v-model="content"></textarea>
                            </div>

                            <a class="btn btn-lg text-white btn-main-1 w-100" :href="`mailto:service@binaryflash.com?subject=${subject}&body=${content}`">Send</a>

                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'contact',
    data(){
        return{
            firstName:"",
            lastName:"",
            email:"",
            phoneNumber:"",
            message:"",
            reCaptcha:"",

            subject:"",
            content:"",
        }
    },

}
</script>



<style lang="scss" scoped>
	@import "@/scss/all.scss";

    #contact{

        background-image: 
            url(~@/assets/img/contact/mails.png),
            url(~@/assets/img/contact/Img_Line_01.svg),
            url(~@/assets/img/contact/Img_Line_02.svg);

        background-repeat: 
            no-repeat,
            no-repeat,
            no-repeat
        ;

        @include media-breakpoint-up(xl) {
            padding-top: 5rem;
            padding-bottom: 5rem;

            background-size: 
                25%,
                60%,
                40%
            ;

            background-position: 
                left 10% top 8rem,
                left 95% top 0%,
                left 5% bottom -50%;
            ;

         }
        
        @include media-breakpoint-between(lg, xl) {
            padding-top: 5rem;
            padding-bottom: 5rem;

			background-size: 
                30%,
                60%,
                60%
            ;

            background-position: 
                left 10% top 8rem,
                left 95% top 0%,
                left 5% bottom -75%
            ;
		}

        @include media-breakpoint-between(md, lg) {
            padding-top: 5rem;
            padding-bottom: 5rem;

            background-size: 
                40%,
                165%,
                0
            ;

            background-position: 
                right 8% top 0rem,
                left -8% top 20%,
                left 0 bottom 0;
		}

        @include media-breakpoint-between(sm, md) {
            padding-top: 5rem;
            padding-bottom: 5rem;

            background-size: 
                38%,
                165%,
                0
            ;

            background-position: 
                right 10% top 0rem,
                left -8% top 20%,
                left 0 bottom 0
            ;

		}

        @include media-breakpoint-down(sm) {
            padding-top: 2rem;
            padding-bottom: 2rem;

            background-size: 
                50%,
                165%,
                0
            ;
            background-position: 
                right 10% top 0rem,
                left -8% top 20%,
                left 0 bottom 0
            ;

		}
    }

    .title{


        @include media-breakpoint-between(md, lg) {
            margin-bottom: 18rem;
		}

        @include media-breakpoint-between(sm, md) {
			margin-bottom: 15rem;
		}

        @include media-breakpoint-down(sm) {
			margin-bottom: 9rem;
		}

    }
    
    .card{
        color: $dark;
        border-radius: 10px;
    }

</style>
